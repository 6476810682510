<!-- @Author: Yu_Bo -->
<template>
    <div class='proxy_list'>
        <div class="vue_title">代理列表</div>

        <div class="main">
            <search-params ref="SearchParams" :is_phone="true" :is_agancy_name="true" :is_agancy_phone="false"
                :is_user_type="true" :user_type_list="user_type_list" :is_identity="true"
                :user_identity_list="user_identity_list" :is_time_field="true" :time_field_list="time_field_list" :is_export='true'
                @search="search" @export='exportBtn'></search-params>
            <table-list :list="list" :loading="loading" :topup="topup" @proxyEdit="proxyEdit" @statusEdit="statusEdit"
                @onSortChange="onSortChange" @getList="getList" @handleSelectionChange="handleSelectionChange"></table-list>
            <div class="page_box pr20">
                <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
                    @currentChange='currentChangeBtn'></paging-page>
            </div>
        </div>
        <!-- 编辑代理 -->
        <proxy-edit ref="ProxyEdit" @refreshList="getList"></proxy-edit>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import SearchParams from '../components/search/index.vue' // 筛选项
import TableList from '../components/tableList/proxyList.vue' // 列表
import ProxyEdit from '../components/proxyEdit/proxyEdit.vue'

export default {
    components: {
        EmptyData,
        PagingPage,
        SearchParams,
        TableList,
        ProxyEdit,
    },
    props: {},
    data() {
        return {
            accountType: 2, // 1 个人 2 企业
            btnType: 2, // 1 添加 2 编辑 3 详情
            list: [
                { id: 1, type: 1, }
            ],
            params: {

            },
            page: 1,
            limit: 10,
            total: 0,
            loading: false,
            user_identity_list: [], // 用户身份类型
            user_type_list: [], // 用户类型
            time_field_list: [],
            topup: [],   //套餐
			ids: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getRechargeType()
        this.getParams()
        this.getList()
    },
    mounted() { },
    methods: {
        getParams() {
            this.$userApi.getAgencyListParams().then(res => {
                if (res.code == 1000) {
                    this.time_field_list = res.result.time_type
                    this.user_type_list = res.result.agency_type
                    this.user_identity_list = res.result.type
                }
            })
        },
        search(params) {
            this.params.mobile = params.phone
            this.params.keyword = params.agancy_name
            this.params.type = params.identity_type
            this.params.agency_type = params.user_type
            this.params.time_type = params.time_field
            this.params.time_period = params.date ? `${params.date[0]}|${params.date[1]}` : ''
            this.page = 1
            this.list = []
            this.total = 0
            this.getList()
        },
        resetFrom() {
            this.$refs.SearchParams.resetFrom()
            this.page = 1
            this.list = []
            this.total = 0
            this.params = {}
            this.getList()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList();
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList();
        },
        // 列表排序
        onSortChange(column) {
            console.log(column)
            if (column.order == 'ascending') this.params.order_redirect = 'asc'
            if (column.order == 'descending') this.params.order_redirect = 'desc'
            if (!column.order) this.params.order_redirect = ''
            this.params.order_field = column.prop
            this.getList()
        },
        getList() {
            const data = {
                page: this.page,
                limit: this.limit,
                ...this.params,
            }
            this.loading = true
            this.$userApi.getAgencyList(data).then(res => {
                this.loading = false

                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 代理编辑
        proxyEdit(id) {
            this.$refs.ProxyEdit.openDialogBtn(id)
        },
        // 状态修改
        statusEdit(info) {
            const data = {
                id: info.id,
                status: info.status
            }
            this.$userApi.agencyStatusUpdate(data).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.getList()
                } else {
                    this.$errMsg(res.message)

                }
            })
        },
        // 获取套餐
        getRechargeType() {
            this.$userApi.rechargeType().then(res => {
                if (res.code == 1000) {
                    let arr = res.result.topup
                    arr.forEach(ele => {
                        this.topup.push({
                            value: ele.value,
                            label: ele.label.name,
                        })
                    })
                }
            })
        },
        // 导出
        exportBtn(params) {
            var that = this
            var obj = {
                ...that.params,
                ids: this.ids.length ? this.ids.join(',') : '',
            }
            that.$userApi.agencyExport(obj).then(res => {
                return
            })
        },
        // 列表勾选
        handleSelectionChange(val) {
            this.ids = val.map(item => item.id)
        }
    },
}
</script>

<style lang='scss' scoped>
@import "./index.scss";

.proxy_list {
    height: calc(100vh - 270px);
}
</style>