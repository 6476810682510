<template>
    <div class='userMeans'>
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="680px" :close-on-click-modal='false'>
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="details_info">
                <div class="info_box">
                    <div class="info_item" v-for="(item, index) in infoList" :key="index">
                        <div class="label">{{ item.label }}</div>
                        <div class="value">{{ item.value }}</div>
                    </div>
                    <div class="info_item">
                        <div class="label">邀请码次数：</div>
                        <div class="value">
                            <el-input type="number" v-model.number="info.code_num" size="small"
                                placeholder="输入邀请码个数"></el-input>
                        </div>
                    </div>
                </div>
                <div class="img_box">
                    <div class="img_box_head">
                        <div class="txt">补充说明：</div>
                    </div>
                    <div class="img_box_con">
                        <el-input type="textarea" :rows="2" placeholder="请输入补充说明" v-model="info.desc">
                        </el-input>
                    </div>
                </div>
                <div class="img_box">
                    <div class="img_box_head">
                        <div class="txt">身份证照片</div>
                    </div>
                    <div class="img_box_con">
                        <div class="img" @click="handleImgPreview(info.idcard_front)">
                            <img :src="info.idcard_front" alt="">
                        </div>
                        <div class="img" @click="handleImgPreview(info.idcard_reverse)">
                            <img :src="info.idcard_reverse" alt="">
                        </div>
                    </div>
                </div>
                <div class="img_box" v-if="info.corp_id > 0">
                    <div class="img_box_head">
                        <div class="txt">营业执照</div>
                    </div>
                    <div class="img_box_con">
                        <div class="img" @click="handleImgPreview(info.business_license)">
                            <img :src="info.business_license" alt="">
                        </div>
                    </div>
                </div>
                <div class="time_box">
                    <div class="label">订阅有效期</div>
                    <div class="value">
                        <el-date-picker size="small" v-model="dyTimes" type="daterange" value-format="yyyy-MM-dd"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <div class="time_box">
                    <div class="label">代理有效期</div>
                    <div class="value">
                        <el-date-picker size="small" v-model="dlTimes" type="daterange" value-format="yyyy-MM-dd"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确
                    定</el-button>
            </div>
        </el-dialog>
        <!-- 图片预览 -->
        <el-dialog :visible.sync="imgPreview" :close-on-click-modal='false'>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>

export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            id: '',
            loading: false,
            imgPreview: false, //预览
            dialogImageUrl: '', // 图片预览
            info: {},
            // dyTimes: [],// 订阅有效期
            // dlTimes: [],// 代理有效期
            subLoading: false,
        }
    },
    computed: {
        title() {
            return this.info.corp_id > 0 ? '编辑企业' : '编辑个人'
        },
        infoList() {
            if (JSON.stringify(this.info) == "{}") {
                return {}
            } else {
                let infoList = [
                    {
                        label: '用户ID：',
                        value: this.info.id
                    },
                    {
                        label: '用户名称：',
                        value: this.info.nickname
                    },
                    {
                        label: '身份：',
                        value: this.info.corp_id > 0 ? '企业' : '个人'
                    },
                    {
                        label: '登录手机号：',
                        value: this.info.mobile
                    },
                    {
                        label: '注册来源：',
                        value: this.info.source_name
                    },
                    {
                        label: '推荐人：',
                        value: this.info.referrer_mobile || '无'
                    },
                    // {
                    //     label: '所属代理商：',
                    //     value: '17873827121'
                    // },
                    {
                        label: '订阅套餐类型：',
                        value: this.info.subscribe_name
                    },
                    // {
                    //     label: '订阅有效期：',
                    //     value: '2023-04-16 15:20:30'
                    // },
                    {
                        label: '钻石余额：',
                        value: this.info.diamond
                    },
                    {
                        label: '钻石总消耗：',
                        value: this.info.diamond_con
                    },
                    {
                        label: '时长余额：',
                        value: this.$times.formatSeconds(this.info.video_duration || 0, 1)
                    },
                    {
                        label: '剩余次数：',
                        value: this.info.major_cust
                    },
                    {
                        label: '购买模型个数：',
                        value: this.info.buy_mo
                    },
                    {
                        label: '定制模型个数：',
                        value: this.info.customize_mo
                    },
                    {
                        label: '作品总数：',
                        value: this.info.total_works_num
                    },
                    {
                        label: '平均作品时长：',
                        value: this.info.avg_works_time
                    },
                    // {
                    //     label: '注册时间：',
                    //     value: '2023-04-16 15:20:30'
                    // },
                    // {
                    //     label: '绑定代理时间：',
                    //     value: '2023-04-16 15:20:30'
                    // },
                    {
                        label: '联系人：',
                        value: this.info.contact
                    },
                    {
                        label: '注册时间：',
                        value: this.info.create_time
                    },
                    {
                        label: '审核代理时间：',
                        value: this.info.audit_time
                    },
                ]
                return infoList
            }
        },
        // 订阅有效期
        dyTimes: {
            get() {
                if (JSON.stringify(this.info) == "{}") {
                    return []
                } else {
                    return this.info.subscribe_started && this.info.subscribe_ended ? [this.info.subscribe_started, this.info.subscribe_ended] : []
                 }
            },
            set(val) {
                if (val) {
                    this.info.subscribe_started = val[0]
                    this.info.subscribe_ended = val[1]
                } else {
                    this.info.subscribe_started = ''
                    this.info.subscribe_ended = ''
                }
            }
        },
        // 代理有效期
        dlTimes: {
            get() {
                if (JSON.stringify(this.info) == "{}") {
                    return []
                } else {
                    return this.info.agency_started && this.info.agency_ended ? [this.info.agency_started, this.info.agency_ended] : []
                }
            },
            set(val) {
                if (val) {
                    this.info.agency_started = val[0]
                    this.info.agency_ended = val[1]
                } else {
                    this.info.agency_started = ''
                    this.info.agency_ended = ''
                }
            }
        },
    },
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn(id) {
            this.dialogVisible = true
            this.id = id
            this.getDetails()
        },
        getDetails() {
            this.loading = true
            this.$userApi.getAgencyDetails({ id: this.id }).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.info = res.result
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 图片预览
        handleImgPreview(url) {
            this.dialogImageUrl = url;
            this.imgPreview = true;
        },
        // 取消
        cancelBtn() {
            this.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            // if (!this.info.code_num) {
            //     this.$errMsg('请输入邀请码数量')
            //     return
            // }
            // if (!this.info.desc) {
            //     this.$errMsg('请输入补充说明')
            //     return
            // }
            // if (!this.dyTimes.length) {
            //     this.$errMsg('请选择订阅有效期')
            //     return
            // }
            // if (!this.dlTimes.length) {
            //     this.$errMsg('请选择代理有效期')
            //     return
            // }
        
            const data = {
                id: this.id,
                code_num: this.info.code_num,
                desc: this.info.desc,
                subscribe_started: this.dyTimes ? this.dyTimes[0] : '', // 订阅有效期
                subscribe_ended: this.dyTimes ? this.dyTimes[1] : '', // 订阅有效期
                agency_started: this.dlTimes ? this.dlTimes[0] : '', // 代理有效期
                agency_ended: this.dlTimes ? this.dlTimes[1] : '', // 代理有效期
            }
            this.subLoading = true
            this.$userApi.agencyUpdate(data).then(res => {
                this.subLoading = false
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.cancelBtn()
                    this.$emit('refreshList')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.userMeans {
    .details_info {
        .info_box {
            display: flex;
            flex-wrap: wrap;

            .info_item {
                display: flex;
                align-items: center;
                width: 50%;
                margin-bottom: 20px;

                .label {
                    width: 98px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .value {
                    flex: 1;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;

                    .el-input {
                        width: 180px;
                    }
                }
            }
        }

        .img_box {
            width: 640px;
            // height: 222px;
            // border-radius: 2px;
            // border: 1px solid #DCDCDC;
            margin-bottom: 20px;

            .img_box_head {
                // height: 42px;
                // background: #F0F2F5;
                // border-radius: 2px;
                // padding: 0 20px;

                .txt {
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    // line-height: 42px;
                }
            }

            .img_box_con {
                display: flex;
                // padding: 20px;
                margin-top: 16px;

                .img {
                    width: 220px;
                    height: 140px;
                    margin-right: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .time_box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .label {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            .value {
                width: 312px;
                margin-left: 12px;
            }
        }
    }
}
</style>
